
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/navigation/affix/BasicUsage.vue";
import EUITargetContainer from "@/views/resources/documentation/element-ui/navigation/affix/TargetContainer.vue";
import EUIFixedPosition from "@/views/resources/documentation/element-ui/navigation/affix/FixedPosition.vue";

export default defineComponent({
  name: "badge",
  components: {
    EUIBasicUsage,
    EUITargetContainer,
    EUIFixedPosition
  },
  setup() {
    setCurrentPageTitle("Affix");
  }
});
